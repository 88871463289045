import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';

import {
  Page,
  SectionOne,
  SectionHeroUuper,
  SectionOneUuper,
  SectionTwoUuper,
  SectionTwoUuperNew,
  SectionThreeUuper,
  SectionFourUuper,
  SectionHowItWorks,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import facebookImage from '../../assets/uupo-facebook-1059x521.jpg';
import twitterImage from '../../assets/uupo-twitter-600x295.jpg';

import css from './LandingUuper.module.css';

export const LandingUuperComponent = (props) => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    latestListings,
    isAuthenticated,
    currentUserHasListings,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingUuper.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingUuper.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const schemaKeywords = intl.formatMessage({ id: 'LandingUuper.schemaKeywords' });

  // prettier-ignore
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      primaryKeywords={intl.formatMessage({ id: 'LandingUuper.keywords' })}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.mainWrapper}>
          <div className={css.heroUuperContainer}>
            <SectionHeroUuper className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <SectionOneUuper />
            </li>
            <li className={css.section}>
              {/* <SectionTwoUuper /> */}
              <SectionTwoUuperNew />
            </li>
            <li className={css.section}>
              {/* <SectionFourUuper /> */}
              <SectionOne latestListings={latestListings} msg={intl.formatMessage({ id: 'SectionOne.browseButton' })} />
            </li>
            <li className={css.section}>
              <SectionThreeUuper isAuthenticated={isAuthenticated} />
            </li>
            <li className={css.section}>
              <SectionHowItWorks />
            </li>
          </ul>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingUuperComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {

  const { currentUserHasListings } = state.user;

  const { isAuthenticated } = state.Auth;
  const { latestListingsIds } = state.LandingPage;
  const latestListings = getListingsById(state, latestListingsIds);
  return {
    latestListings: latestListings,
    isAuthenticated,
    currentUserHasListings,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const LandingUuper = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingUuperComponent);

export default LandingUuper;
